import Paragraph from "@components/elements/paragraph"
import React, { useState } from "react"
import { TabContainer, TabPanel } from "../tab"
import Flex from "@components/elements/flex"
import Div from "@components/elements/div"
import Heading from "@components/elements/heading"
import GradientText from "@components/common/gradient-text"
import styled, { useTheme } from "styled-components"
import Image from "@components/image"
import media from "../../../../styles/media"
import { motion } from "framer-motion"
import PropTypes from "prop-types"
import { graphql, useStaticQuery } from "gatsby"
import { processImages } from "@utils/process-images"

const HeroWrapperGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "stack";
`

const AnimatedHeroWrapper = styled(motion.div)`
  grid-area: stack;

  .klipfolio-image-wrapper div {
    border-radius: 1rem;

    ${media.sm`
      border-radius: 0.5rem;
    `}
  }
`

const HeroWrapper = ({ active, shadow, image }) => (
  <AnimatedHeroWrapper
    variants={{
      active: {
        opacity: 1,
      },
      inactive: {
        opacity: 0,
      },
    }}
    style={{ display: active ? null : "none" }}
    animate={active ? "active" : "inactive"}
  >
    <Image
      borderStyle={{
        boxShadow: shadow,
      }}
      radius={1}
      file={image}
    />
  </AnimatedHeroWrapper>
)

HeroWrapper.propTypes = {
  active: PropTypes.bool.isRequired,
  shadow: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
}

export const PowerMetricsFeatureGallery = () => {
  const { imageData } = useStaticQuery(graphql`
    query PowerMetricsFeatureGallery {
      imageData: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "44ac8046-668e-421f-a2a8-9a613f21fecb"
              "0f5365e0-a110-4f32-9523-de4ad111e4cf"
              "ad5ce3a8-e3a5-461a-9eae-33c129f8f832"
              "730ecad8-990c-4691-b1c8-ee041985a88b"
              "ccc72d7b-93e5-4891-a636-95f432732280"
              "9aff4ef8-b1b6-4342-ab28-c537fcb54ec6"
              "7716872c-e62c-4dcb-8ba8-8219d8f2523a"
              "0201ad95-f668-4e4e-8fa0-1baeeaf639e5"
              "636649f5-7a25-48f3-818b-4e1d888cb5bf"
              "94888d1e-52b4-498a-b3d8-d7d4a5b2a3b2"
            ]
          }
        }
      ) {
        edges {
          node {
            directus_id
            cdn
            id
            placeholder
            title
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)

  const [heroOne, setHeroOne] = useState(0)
  const [heroTwo, setHeroTwo] = useState(0)
  const [heroThree, setHeroThree] = useState(0)

  const { shadow } = useTheme()

  return (
    <Flex
      textContainer
      gap="10rem"
      gapSm="4rem"
      margin="10rem auto"
      marginSm="4rem auto"
    >
      <Div>
        <Heading as="h5" margin="0 0 0.5rem">
          Define metrics once
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Work with your
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            single source of truth.
          </GradientText>
        </Heading>
        <Paragraph>
          Ensure SSOT data with certified, defined, governed metrics. Your data
          lives where it should, in your data warehouse, semantic layer, or, if
          you use data feeds, within PowerMetrics. Our hybrid design means you
          can choose one or more integration method and modify your choices as
          your data stack evolves.
        </Paragraph>
        <TabContainer
          tabs={["Data Warehouses", "Semantic Layers", "Other Services"]}
          activeIndex={heroOne}
          setActiveIndex={setHeroOne}
        />
        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="3rem"
          paddingSm="2rem"
        >
          <TabPanel index={0} activeIndex={heroOne}>
            <Paragraph>
              Query data directly from your data warehouse and get metrics on
              demand in real time.
            </Paragraph>
          </TabPanel>
          <TabPanel index={1} activeIndex={heroOne}>
            <Paragraph>
              Connect to metrics from a dbt Semantic Layer project or to data
              from Cube for visualization and analysis.
            </Paragraph>
          </TabPanel>
          <TabPanel index={2} activeIndex={heroOne}>
            <Paragraph>
              Connect to data sources once and build one or more data feeds than
              can be used across multiple metrics. Data is stored in a
              time-series database in PowerMetrics.
            </Paragraph>
          </TabPanel>
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroOne-0"
              active={heroOne === 0}
              shadow={shadow.dramatic}
              image={images["44ac8046-668e-421f-a2a8-9a613f21fecb"]}
            />
            <HeroWrapper
              key="heroOne-1"
              active={heroOne === 1}
              shadow={shadow.dramatic}
              image={images["0f5365e0-a110-4f32-9523-de4ad111e4cf"]}
            />
            <HeroWrapper
              key="heroOne-2"
              active={heroOne === 2}
              shadow={shadow.dramatic}
              image={images["ad5ce3a8-e3a5-461a-9eae-33c129f8f832"]}
            />
          </HeroWrapperGrid>
        </Div>
      </Div>
      <Div>
        <Heading as="h5" margin="0 0 0.5rem">
          A catalog of metrics
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Manage metrics in
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            one place.
          </GradientText>
        </Heading>
        <Paragraph>
          Keep everyone informed with a governed, centralized catalog of curated
          metrics and summarized overviews of data trends. Business users
          benefit from an all-in-one experience, enriched by the ability to
          combine metrics as calculations for even deeper insights.
        </Paragraph>

        <TabContainer
          tabs={[
            "Metric Management",
            "Metric Governance",
            "Metric Calculation",
          ]}
          activeIndex={heroTwo}
          setActiveIndex={setHeroTwo}
        />

        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="3rem"
          paddingSm="2rem"
        >
          <TabPanel index={0} activeIndex={heroTwo}>
            <Paragraph>
              Find, view details for, and go to metrics via the metric catalog.
              A scorecard-like view provides an at-a-glance summary of your
              metrics’ status.
            </Paragraph>
          </TabPanel>
          <TabPanel index={1} activeIndex={heroTwo}>
            <Paragraph>
              Control access to the organization’s metrics with user roles and
              view and edit permissions. Set approved metrics as ‘certified’ to
              ensure all business teams are analyzing the right data.
            </Paragraph>
          </TabPanel>
          <TabPanel index={2} activeIndex={heroTwo}>
            <Paragraph>
              Get deeper insights by combining existing metrics. All metrics,
              regardless of their source or type, can be used interchangeably to
              create calculated metrics.
            </Paragraph>
          </TabPanel>
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroTwo-0"
              active={heroTwo === 0}
              shadow={shadow.dramatic}
              image={images["ccc72d7b-93e5-4891-a636-95f432732280"]}
            />
            <HeroWrapper
              key="heroTwo-1"
              active={heroTwo === 1}
              shadow={shadow.dramatic}
              image={images["730ecad8-990c-4691-b1c8-ee041985a88b"]}
            />
            <HeroWrapper
              key="heroTwo-2"
              active={heroTwo === 2}
              shadow={shadow.dramatic}
              image={images["0201ad95-f668-4e4e-8fa0-1baeeaf639e5"]}
            />
          </HeroWrapperGrid>
        </Div>
      </Div>
      <Div>
        <Heading id="self-serve" as="h5" margin="0 0 0.5rem">
          Independent analytics
        </Heading>
        <Heading as="h2" fontSize="3rem" margin="0 0 1.5rem">
          Straightforward analytics for
          <br />
          <GradientText gradient="linear-gradient(90deg, #FFB85C 0%, #FDA1B9 40%, #707EFA 140%)">
            everyone, everywhere.
          </GradientText>
        </Heading>
        <Paragraph margin="0 0 1rem">
          Our flexible design helps business users analyze data no matter where
          they work. For some, that’s in PowerMetrics, on a dashboard, metric
          homepage or in the Explorer (with optional help from PowerMetrics AI).
          For others, analysis takes place outside PowerMetrics, as live embeds
          or within alternate data tools.
        </Paragraph>
        <TabContainer
          tabs={[
            "Integrated Analytics",
            "PowerMetrics AI",
            "External Metric Consumption",
          ]}
          activeIndex={heroThree}
          setActiveIndex={setHeroThree}
        />

        <Div
          borderRadius="1rem"
          background={"#F7F8FF"}
          padding="3rem"
          paddingSm="2rem"
        >
          <TabPanel index={0} activeIndex={heroThree}>
            <Paragraph>
              Streamlined, no-code analytics, designed for business users.
              Opportunities for discovery and insights abound, whether
              performing free-form analysis in the Explorer, monitoring trends
              in a metric homepage, or assembling dashboards and reports,
              PowerMetrics makes it easy to learn from and act on your data.
            </Paragraph>
          </TabPanel>
          <TabPanel index={1} activeIndex={heroThree}>
            <Paragraph>
              Have a conversation with your business data. PowerMetrics includes
              a natural language interface that understands your intentions and
              uses generative AI to build the visualizations that answer your
              questions.
            </Paragraph>
          </TabPanel>
          <TabPanel index={2} activeIndex={heroThree}>
            <Paragraph>
              Meet data consumers where they work. Share metrics as live embeds
              or access metrics for visualization and analysis in various
              consumption endpoints, such as Enterprise BI, data apps, data
              orchestration tools, notebooks and analysis tools.
            </Paragraph>
          </TabPanel>
          <HeroWrapperGrid>
            <HeroWrapper
              key="heroThree-0"
              active={heroThree === 0}
              shadow={shadow.dramatic}
              image={images["9aff4ef8-b1b6-4342-ab28-c537fcb54ec6"]}
            />
            <HeroWrapper
              key="heroThree-1"
              active={heroThree === 1}
              shadow={shadow.dramatic}
              image={images["636649f5-7a25-48f3-818b-4e1d888cb5bf"]}
            />
            <HeroWrapper
              key="heroThree-2"
              active={heroThree === 2}
              shadow={shadow.dramatic}
              image={images["94888d1e-52b4-498a-b3d8-d7d4a5b2a3b2"]}
            />
          </HeroWrapperGrid>
        </Div>
      </Div>
    </Flex>
  )
}

PowerMetricsFeatureGallery.propTypes = {
  handleClick: PropTypes.func.isRequired,
}
